import { useEffect, useState } from 'react';
import { Log } from '../../../logging/src/Log';
import { LayoutMode, UserContext, buildRequestHeadersWithAuthToken, onLayoutChanged } from '../../@data';
import { uiConfig } from '../../config/ui.config';
import { usePUID, usePreloadSVGImages } from '../../hooks';
import emailLogo from './emailLogo.svg';
import emailSentLogo from './emailSentLogo.svg';
import { UseSupportPage } from './types';

export const MAX_WORD_COUNT = 700;
const postFormSubmission = async (props: {
  title: string;
  description: string;
  email: string;
  puid: string;
  userContext: UserContext;
  formattedLocale: string;
  countryCode: string;
}) => {
  const { title, description, email, puid, userContext, formattedLocale, countryCode } = props;
  const url = uiConfig.getApiBaseAddress() + '/dynamicsCRM/createAuthenticatedCase';
  const headers = await buildRequestHeadersWithAuthToken(url, userContext);

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        title,
        description,
        email,
        puid,
        SupportLanguage: formattedLocale,
        SupportCountry: countryCode,
      }),
    });

    if (!response.ok) {
      return Promise.reject(response);
    }

    const responseJson: { data: { caseNumber: string } } = await response.json();
    if (responseJson.data) {
      return Promise.resolve(responseJson.data);
    }
  } catch (err) {
    const error = `Search operation failed for ${url}. Errors: ${err}`;
    return Promise.reject(error);
  }
};

export const useSupportPage: UseSupportPage = ({ appStore, location, formattedLocale, countryCode }) => {
  const { userContext } = appStore;
  const [titleInput, setTitleInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [emailInput, setEmailInput] = useState(userContext?.email ?? '');
  const [shouldRenderFailureMessage, setShouldRenderFailureMessage] = useState(false);
  const [createdTicketCaseNumber, setCreatedTicketCaseNumber] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);
  const puid = usePUID();
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isFormValid =
    titleInput.length > 0 && descriptionInput.length > 0 && emailRegex.test(emailInput) && descriptionInput.length <= MAX_WORD_COUNT;

  const onFormSubmit = async () => {
    if (puid && userContext) {
      setIsFormLoading(true);
      setShouldRenderFailureMessage(false);
      postFormSubmission({
        title: titleInput,
        description: descriptionInput,
        email: emailInput,
        puid,
        userContext,
        formattedLocale,
        countryCode,
      })
        .then((data) => {
          setCreatedTicketCaseNumber(data!.caseNumber);
          // Accessibility enhancement: once submit success, focus on terms and conditions link
          const termsAndConditionsLink = document?.querySelector?.('a[href="/terms-and-conditions"]') as HTMLAnchorElement | null;
          termsAndConditionsLink?.focus?.();
        })
        .catch((err) => {
          Log.error(err);
          setShouldRenderFailureMessage(true);
        })
        .finally(() => setIsFormLoading(false));
    }
  };

  const onDescriptionChange = (description: string) => {
    if (description.length <= MAX_WORD_COUNT) {
      setDescriptionInput(description);
    }
  };

  usePreloadSVGImages([emailLogo, emailSentLogo]);

  useEffect(() => {
    onLayoutChanged(LayoutMode.Default);
    /**
     * remove required attribute from the form fields, this is for accessibility
     * workaround that Edge is interacting with the required attribute with tooltip
     * rendered unexpectedly. remove the required attribute to avoid the tooltip
     * render by browser, in user perspective we have disabled submit button while
     * inputs are empty and added required UX to support the experience. Tooltip is
     * redundant in this case.
     */
    document.getElementById('supportTitleInput')?.removeAttribute('required');
    document.getElementById('supportDescriptionInput')?.removeAttribute('required');
    document.getElementById('supportEmailInput')?.removeAttribute('required');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldRenderFailureMessage) {
      setShouldRenderFailureMessage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionInput, titleInput, emailInput]);

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const subject = queryParams.get('subject');

    // Set the form title if 'subject' query param exists
    if (subject) {
      setTitleInput(subject);
    }
  }, [location.search]); // Depend on 'search' part of the location object

  return {
    titleInput,
    descriptionInput,
    emailInput,
    isFormValid,
    shouldRenderFailureMessage,
    createdTicketCaseNumber,
    isFormLoading,
    setTitleInput,
    setEmailInput,
    onDescriptionChange,
    onFormSubmit,
  };
};

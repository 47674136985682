import { IPanelProps, List, PrimaryButton, mergeStyleSets } from '@fluentui/react';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import {
  NotificationLabel,
  NotificationSeverity,
  getAppStore,
  onDismissAllNotifications,
  onDismissNotification,
  onNotificationPanelClosed,
  primaryExternalNotificationLabels,
} from '../../../../@data';
import notificationMessages from '../../../../NotificationsPanel.Types.messages';
import { FormSection } from '../../../../components/Form';
import { INotificationCardItem, NotificationCard } from '../../../../components/NotificationCard';
import { onResendClarityLink } from '../../../../pages/ad-management/Property/Create/__data__/orchestrators';
import { setNotificationPanelTriggered } from '../../../../pages/terms-and-conditions/@data';
import { injectIntlAndObserver } from '../../../../utils';
import messages from './NotificationsPanel.messages';
import { getClassNames } from './NotificationsPanel.styles';
import emptyNotifications from './resources/empty.svg';

export const renderNotificationFooterContent = (panelProps: IPanelProps) => <NotificationPanelFooterButtons {...panelProps} />;

export const NotificationPanelFooterButtons = observer(function (panelProps: IPanelProps) {
  const { dismissAll } = mergeStyleSets(getClassNames());
  const { panelNotifications, notificationPanelCollapsed } = getAppStore();
  if (notificationPanelCollapsed) {
    panelProps.onDismiss?.();
    onNotificationPanelClosed(false);
  }

  const onDismissAll = (): void => {
    onDismissAllNotifications();
  };

  return (
    <div>
      {panelNotifications && panelNotifications.length > 0 && (
        <div>
          <PrimaryButton className={dismissAll} onClick={() => onDismissAll()}>
            <FormattedMessage {...messages.dismissAll} />
          </PrimaryButton>
        </div>
      )}
    </div>
  );
});

export const NotificationsPanel = injectIntlAndObserver(
  class extends React.Component<InjectedIntlProps> {
    public render(): JSX.Element {
      const { formatMessage } = this.props.intl;
      const { emptySection, panelParent, textContent, img } = mergeStyleSets(getClassNames());
      const { panelNotifications } = getAppStore();
      const onRenderNotificationCard = (item: INotificationCardItem, index: number): JSX.Element => {
        return (
          <NotificationCard
            title={
              item.notificationLabel
                ? formatMessage(notificationMessages[item.notificationLabel + 'Title'])
                : item.title
                ? item.title
                : item.titleMessageDescriptor
                ? formatMessage(item.titleMessageDescriptor)
                : ''
            }
            severity={item.severity}
            timestamp={item.timestamp!}
            content={
              item.notificationLabel
                ? formatMessage(notificationMessages[item.notificationLabel + 'Content'], item.intlContentMessageValues)
                : item.content
                ? item.content
                : item.contentMessageDescriptor
                ? formatMessage(item.contentMessageDescriptor, item.intlContentMessageValues)
                : ''
            }
            dismissLabel={formatMessage(messages.dismiss)}
            onDismiss={() => this._onDismiss(item)}
            yesterdayLabel={formatMessage(messages.yesterday)}
            threeDaysAgoLabel={formatMessage(messages.threeDaysAgo)}
            twoDaysAgoLabel={formatMessage(messages.twoDaysAgo)}
            primaryActionLink={item.primaryActionLink}
            primaryActionText={
              item.primaryActionTextMessageDescriptor
                ? formatMessage(item.primaryActionTextMessageDescriptor)
                : item.notificationLabel
                ? notificationMessages[item.notificationLabel + 'PrimaryActionLabel']
                  ? formatMessage(notificationMessages[item.notificationLabel + 'PrimaryActionLabel'])
                  : item.primaryActionText
                : item.primaryActionText
            }
            secondaryActionLink={item.secondaryActionLink}
            secondaryActionText={
              item.notificationLabel
                ? notificationMessages[item.notificationLabel + 'SecondaryActionLabel']
                  ? formatMessage(notificationMessages[item.notificationLabel + 'SecondaryActionLabel'])
                  : item.secondaryActionText
                : item.secondaryActionText
            }
            onPrimaryActionClicked={() => this._onPrimaryActionClicked(item)}
            onSecondaryActionClicked={() => this._onSecondaryActionClicked(item)}
            primaryIsExternal={this._isPrimaryExternal(item)}
            secondaryIsExternal={item.secondaryActionText !== undefined}
          />
        );
      };

      const criticalNotifications =
        panelNotifications && panelNotifications.filter((notification) => notification.severity === NotificationSeverity.Critical);
      const warningNotifications =
        panelNotifications && panelNotifications.filter((notification) => notification.severity === NotificationSeverity.Warning);
      const infoNotifications =
        panelNotifications && panelNotifications.filter((notification) => notification.severity === NotificationSeverity.Informational);
      const errorNotifications =
        panelNotifications && panelNotifications.filter((notification) => notification.severity === NotificationSeverity.Error);

      criticalNotifications?.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
      warningNotifications?.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
      infoNotifications?.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
      errorNotifications?.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());

      return (
        <div className={panelNotifications?.length === 0 ? panelParent : ''}>
          {panelNotifications?.length === 0 && (
            <div className={emptySection}>
              <img className={img} src={emptyNotifications} alt={emptyNotifications} />
              <p className={textContent}>{formatMessage(messages.allSet)}</p>
            </div>
          )}
          {criticalNotifications && criticalNotifications.length > 0 && (
            <div>
              <FormSection
                title={formatMessage(messages.critical) + ' (' + criticalNotifications.length + ')'}
                formWidth={412}
                rightChevron
                expanded={true}
              >
                <List items={Object.values(criticalNotifications)} onRenderCell={onRenderNotificationCard} />
              </FormSection>
            </div>
          )}

          {warningNotifications && warningNotifications.length > 0 && (
            <div>
              <FormSection
                title={formatMessage(messages.warning) + ' (' + warningNotifications.length + ')'}
                formWidth={412}
                rightChevron
                expanded={true}
              >
                <List items={Object.values(warningNotifications)} onRenderCell={onRenderNotificationCard} />
              </FormSection>
            </div>
          )}

          {errorNotifications && errorNotifications.length > 0 && (
            <div>
              <FormSection
                title={formatMessage(messages.error) + ' (' + errorNotifications.length + ')'}
                formWidth={412}
                rightChevron
                expanded={true}
              >
                <List items={Object.values(errorNotifications)} onRenderCell={onRenderNotificationCard} />
              </FormSection>
            </div>
          )}

          {infoNotifications && infoNotifications.length > 0 && (
            <div>
              <FormSection
                title={formatMessage(messages.informational) + ' (' + infoNotifications.length + ')'}
                formWidth={412}
                rightChevron
                expanded={true}
              >
                <List items={Object.values(infoNotifications)} onRenderCell={onRenderNotificationCard} />
              </FormSection>
            </div>
          )}
        </div>
      );
    }

    private _onDismiss = (item: INotificationCardItem): void => {
      onDismissNotification(item);
    };

    private _onPrimaryActionClicked = (item: INotificationCardItem): void => {
      if (item.notificationLabel && item.notificationLabel === NotificationLabel.PolicyUpdate) {
        setNotificationPanelTriggered(true);
      }
      if (
        item.notificationLabel &&
        (item.notificationLabel === NotificationLabel.ClarityLinkDeclined ||
          item.notificationLabel === NotificationLabel.ClarityLinkExpired)
      ) {
        if (item.propertyBag) {
          const propertyId = item.propertyBag['propertyId'];
          const clarityId = item.propertyBag['clarityId'];
          onResendClarityLink(propertyId, clarityId);
        }
      }
      onDismissNotification(item);
      onNotificationPanelClosed(true);
    };

    private _onSecondaryActionClicked = (item: INotificationCardItem): void => {
      onDismissNotification(item);
      onNotificationPanelClosed(true);
    };

    private _isPrimaryExternal = (item: INotificationCardItem): boolean => {
      return !!item.notificationLabel && primaryExternalNotificationLabels.includes(item.notificationLabel);
    };
  }
);
